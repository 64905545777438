@import './styles/variables';
// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';
@import './styles/Loading.scss';

html,
body,
.navbar {
  background-color: $background;
}

.logo {
  background-image: url('./assets/images/Logo@1x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 250px;
  height: 70px;
}

.logo-lg {
  margin: 0 auto;
  background-image: url('./assets/images/Logo@1x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 500px;
  height: 70px;
}

h1 {
  font-weight: 300 !important;
  font-size: 2.25rem !important;
}
h2 {
  font-weight: 300 !important;
  font-size: 1.5rem !important;
}

h3 {
  font-weight: 900 !important;
  font-size: 1.45rem !important;
}

.bold {
  font-weight: 900;
}

.card {
  border-radius: 1rem !important;

  .card-header {
    box-shadow: none !important;
    padding: 0.5em !important;

    .card-header-title {
      font-weight: 300 !important;
      font-size: 1.35rem;

      .card-subtitle {
        font-size: 1rem;
      }
    }

    .card-header-link {
      padding: 0.75rem 1rem;
    }
  }

  .card-footer {
    .card-footer-item {
      &.is-danger {
        color: $danger;
      }
      &.is-danger:hover {
        color: darken($danger, 25%) !important;
      }
    }
  }
}

.full-height-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.vertical-centered-content {
  max-width: 500px;
}

.login-header {
  margin-bottom: 2rem;
}

.login-footer {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;

  a {
    margin: 0.5rem auto;
  }
}

.category-icons {
  height: 35px;
  margin-right: 1rem;
}

.chart-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  max-width: 300px !important;
  margin: 0 auto !important;
}

.doughnut-chart {
  max-width: 300px !important;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50% !important;
}
.w-33 {
  width: 33% !important;
}
.w-25 {
  width: 25% !important;
}

.p-200 {
  padding-left: 2rem;
}

.bounding-box {
  border: 1px solid rgb(233, 233, 233);
  padding: 2rem;
  border-radius: 10px;
}

.modal {
  .modal-card {
    .modal-card-head,
    .modal-card-foot {
      background-color: $white;
      border: 0 !important;

      .modal-card-title {
        font-weight: bold;
        font-size: 2rem;
      }
    }
  }
}

p.paragraph {
  display: block;
  margin-block-start: 0.75em;
  margin-block-end: 0.75rem;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.blend-box {
  > p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

.floated-chart {
  float: right;
  margin-left: 0.5rem !important;
  margin-bottom: 0.25rem !important;
}

.box {
  padding: 2rem !important;
  border-radius: 1rem !important;
}

.video-container {
  width: 100%;
  text-align: center;
}

.video-player {
  border-radius: 1rem;
  background-color: $black;
  margin: 0 auto;
}

.video-chevron {
  font-size: 68px;
}

button {
  outline: none !important;
}

.eval-box {
  margin: 0 auto;
  max-width: 600px;
}

.eval-question {
  font-size: 2.25rem;
  font-weight: 300;
  min-height: 200px;
  align-items: center;
  display: flex;
}

.eval-button {
  font-weight: 900;
  // font-size: 1.15rem;
  display: block !important;
  width: 85% !important;
  text-transform: uppercase;
  margin: 0.75rem auto;
}

.toast-container {
  z-index: 999;
  max-width: 350px;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.purchase-flexbox {
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto;
  display: flex;
}

.purchase-count {
  font-size: 5rem;
  font-weight: bold;
}

.purchase-dollar {
  font-size: 1.25rem;
}

.plus-minus-button {
  font-size: 1.5rem;
  font-weight: bold;
}

.negative-margin-half {
  margin-top: -0.5rem;
}

.icon-header {
  font-size: 6rem;

  &.success {
    color: $success;
  }

  &.failed {
    color: $danger;
  }
}

.progress-container {
  background-color: rgb(233, 233, 233);
  border-radius: 0.5rem;
  width: 100%;
}

.progress {
  height: 10px;
  border-radius: 1rem;

  &.thinker {
    background-color: $cyan;
  }
  &.doer {
    background-color: $red;
  }
  &.feeler {
    background-color: $forest;
  }
}

.mobile-new-test-button {
  margin-top: 1.5rem;
  padding: 0 1.5rem 0 1.5rem;
}
